import React, { useState, useEffect, Fragment } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "./AppTopbar";
import { AppFooter } from "./AppFooter";
import { AppMenu } from "./AppMenu";
import { AppConfig } from "./AppConfig";

import { Dashboard } from "./components/Dashboard";
import { ButtonDemo } from "./components/ButtonDemo";
import { ChartDemo } from "./components/ChartDemo";
import { Documentation } from "./components/Documentation";
import { FileDemo } from "./components/FileDemo";
import { FloatLabelDemo } from "./components/FloatLabelDemo";
import { FormLayoutDemo } from "./components/FormLayoutDemo";
import { InputDemo } from "./components/InputDemo";
import { ListDemo } from "./components/ListDemo";
import { MenuDemo } from "./components/MenuDemo";
import { MessagesDemo } from "./components/MessagesDemo";
import { MiscDemo } from "./components/MiscDemo";
import { OverlayDemo } from "./components/OverlayDemo";
import { PanelDemo } from "./components/PanelDemo";
import { TableDemo } from "./components/TableDemo";
import { TreeDemo } from "./components/TreeDemo";
import { InvalidStateDemo } from "./components/InvalidStateDemo";

import { Crud } from "./pages/Crud";
import { EmptyPage } from "./pages/EmptyPage";
import { TimelineDemo } from "./pages/TimelineDemo";
import Login from "./auth/Login";

import PrimeReact from "primereact/api";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedInUserInformation, storeLoggedInformation } from "./auth/authSlice";
import Users from "pages/Users/Users";
import AddUser from "pages/Users/AddUser/AddUser";
import AddRole from "pages/Roles/AddRole/AddRole";
import Roles from "pages/Roles/Roles";
// import Annotation from "pages/Annotation/Annotation";

import Signup from "auth/Signup";
import Segments from "pages/Segments/Segments";
import AddSegment from "pages/Segments/AddSegment/AddSegment";
import Solutions from "pages/Solutions/Solutions";
import AddSolution from "pages/Solutions/AddSolution/AddSolution";
import Exhibitors from "pages/Exhibitors/Exhibitors";
import Reports from "pages/Reports/Reports";

const App = () => {
    const [layoutMode, setLayoutMode] = useState("static");
    const [layoutColorMode, setLayoutColorMode] = useState("light");
    const [inputStyle, setInputStyle] = useState("outlined");
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [processorMenu, setProcessorMenu] = useState([
        {
            label: "Home",
            items: [{ label: "Trainings", icon: "pi pi-fw pi-list", to: "/trainings" }],
        },
        {
            label: "Help",
            items: [
                { label: "FAQs", icon: "pi pi-fw pi-list", to: "/faqs" },
                { label: "Contact Details", icon: "pi pi-fw pi-bookmark", to: "/contact-details" },
            ],
        },
    ]);
    PrimeReact.ripple = true;

    let menuClick = false;
    let mobileTopbarMenuClick = false;
    const dispatch = useDispatch();
    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    const loggedInUserInformation = useSelector(getLoggedInUserInformation);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode);
    };

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode);
    };

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    };

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === "overlay") {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            } else if (layoutMode === "static") {
                setStaticMenuInactive((prevState) => !prevState);
            }
        } else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarClick = () => {
        menuClick = true;
    };

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    };
    const isDesktop = () => {
        return window.innerWidth >= 992;
    };

    const menu = [
        {
            label: "Home",
            items: [
                {
                    label: "Dashboard",
                    icon: "pi pi-fw pi-home",
                    to: "/",
                },
                {
                    label: "Reports",
                    icon: "pi pi-fw pi-bookmark",
                    to: "/reports",
                },
                
            ],
        },
        {
            label: "Masters",
            icon: "pi pi-fw pi-sitemap",
            items: [
                { label: "Solutions", icon: "pi pi-fw pi-circle-off", to: "/solutions" },
                { label: "Segments", icon: "pi pi-fw pi-bookmark", to: "/segments" },
                { label: "Exhibitors", icon: "pi pi-fw pi-exclamation-circle", to: "/exhibitors" },
                { label: "Portal Users", icon: "pi pi-fw pi-id-card", to: "/users" },
            ],
        },
    ];

    useEffect(() => {
        if (!loggedInUserInformation?.id) {
            const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
            dispatch(storeLoggedInformation(sessionInformation));
        }
    }, []);

    const addClass = (element, className) => {
        if (element.classList) element.classList.add(className);
        else element.className += " " + className;
    };

    const removeClass = (element, className) => {
        if (element.classList) element.classList.remove(className);
        else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    };

    const wrapperClass = classNames("layout-wrapper", {
        "layout-overlay": layoutMode === "overlay",
        "layout-static": layoutMode === "static",
        "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
        "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
        "layout-mobile-sidebar-active": mobileMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": ripple === false,
        "layout-theme-light": layoutColorMode === "light",
    });

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const sessionInformation = JSON.parse(sessionStorage.getItem("userInfo"));
    useEffect(() => {
        if (loggedInUserInformation && loggedInUserInformation?.id) {
            setIsLoggedIn(true);
        }
        else if (sessionInformation && sessionInformation.id) {
            setIsLoggedIn(true);
        }
    }, [loggedInUserInformation]);
    return (
        <Fragment>
            <div className={wrapperClass} onClick={onWrapperClick}>
                <AppTopbar
                    onToggleMenuClick={onToggleMenuClick}
                    layoutColorMode={layoutColorMode}
                    mobileTopbarMenuActive={mobileTopbarMenuActive}
                    onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                    onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                    isLoggedIn={isLoggedIn}
                    loggedInUserInformation={loggedInUserInformation}
                />
                <div className="wrapperContainer">
                    {isLoggedIn && sessionInformation.role_id === 1 && (
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} isLoggedIn={isLoggedIn} />
                        </div>
                    )}

                    {isLoggedIn && loggedInUserInformation.role_id !== 1 && (
                        <div className="layout-sidebar" onClick={onSidebarClick}>
                            <AppMenu model={processorMenu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} isLoggedIn={isLoggedIn} />
                        </div>
                    )}

                    {!isLoggedIn && (
                        <div className="">
                            <div className="container-fluid flex justify-content-center align-items-center">
                                <div className="layout-logIn">
                                    <Route path="/" exact component={Login} />
                                    <Route path="/login" exact component={Login} />
                                    <Route path="/signup/:id" exact component={Signup} />
                                    <AppFooter layoutColorMode={layoutColorMode} />
                                </div>
                            </div>
                        </div>
                    )}

                    {isLoggedIn && sessionInformation.role_id === 1 && (
                        <div className="layout-main-container">
                            <div className="layout-main">
                                <Route path="/" exact component={Dashboard} />
                                <Route path="/dashboard" exact component={Dashboard} />
                                <Route path="/users" exact component={Users} />
                                <Route path="/users/add-new" exact component={AddUser} />
                                <Route path="/users/edit/:id" exact component={AddUser} />
                                <Route path="/roles" exact component={Roles} />
                                <Route path="/roles/add-new" exact component={AddRole} />
                                <Route path="/roles/edit/:id" exact component={AddRole} />
                                <Route path="/segments" exact component={Segments} />
                                <Route path="/segments/add-new" exact component={AddSegment} />
                                <Route path="/segments/edit/:id" exact component={AddSegment} />
                                <Route path="/solutions" exact component={Solutions} />
                                <Route path="/solutions/add-new" exact component={AddSolution} />
                                <Route path="/solutions/edit/:id" exact component={AddSolution} />
                                <Route path="/exhibitors" exact component={Exhibitors} />
                                <Route path="/reports" exact component={Reports} />
                            </div>

                            <AppFooter layoutColorMode={layoutColorMode} />
                        </div>
                    )}
                </div>
                <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange} layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

                <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                    <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
            </div>
        </Fragment>
    );
};

export default App;
